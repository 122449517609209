// eslint-disable-next-line wix-custom-rules/wixstores-file-names
import {ComWixEcommerceValidationsSpiV1TargetNameInOther, ViolationOtherTargetFragment} from '../../gql/graphql';

export class ViolatioOtherTargetModel {
  public name?: ViolationOtherTargetName;
  constructor(otheTarget?: ViolationOtherTargetFragment) {
    this.name = otheTarget?.name ? toViolationOtherTargetName(otheTarget?.name) : /* istanbul ignore next */ undefined;
  }
}

export enum ViolationOtherTargetName {
  default = 'OTHER_DEFAULT',
  subtotal = 'SUBTOTAL',
}

const toViolationOtherTargetName = (otherTargetName: ComWixEcommerceValidationsSpiV1TargetNameInOther) => {
  switch (otherTargetName) {
    case ComWixEcommerceValidationsSpiV1TargetNameInOther.OTHER_DEFAULT:
      return ViolationOtherTargetName.default;
    /* istanbul ignore next */
    case ComWixEcommerceValidationsSpiV1TargetNameInOther.SUBTOTAL:
      /* istanbul ignore next */
      return ViolationOtherTargetName.subtotal;
  }
};
