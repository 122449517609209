import {FormValues} from '@wix/form-viewer';
import {MultilineAddressValue} from '@wix/form-fields';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {AddressModel} from '../../../../domain/models/Address.model';
import {DEFAULT_COUNTRY, SPECS} from '../../constants';
import {CashierMandatoryFieldsOverrides} from '../../../../types/app.types';
import {Experiments} from '@wix/yoshi-flow-editor';
import {ApiAddressFragment} from '../../../../gql/graphql';

export const getAddressFormOverrides = ({
  experiments,
  checkoutSettings,
  overrides,
}: {
  experiments?: Experiments;
  checkoutSettings: CheckoutSettingsModel;
  overrides?: CashierMandatoryFieldsOverrides;
}) => {
  return {
    address: {
      subdivision: {
        ...(overrides?.state ? {hidden: false, required: true} : {}),
      },
      address_line: {
        /* istanbul ignore next: test forms */ autocomplete: !experiments?.enabled(
          SPECS.CheckoutExcludeGoogleAutoComplete
        ),
        ...(overrides?.address ? {hidden: false, required: true} : {}),
      },
      street_name: {
        /* istanbul ignore next: test forms */ autocomplete: !experiments?.enabled(
          SPECS.CheckoutExcludeGoogleAutoComplete
        ),
        ...(overrides?.street ? {hidden: false, required: true} : {}),
      },
      street_number: {
        ...(overrides?.houseNumber ? {hidden: false, required: true} : {}),
      },
      postal_code: {
        ...(overrides?.zipCode ? {hidden: false, required: true} : {}),
      },
      address_line_2: {
        hidden: !checkoutSettings.addressLine2.show,
        required: checkoutSettings.addressLine2.mandatory,
      },
    },
  };
};

export const getAddressFormInitialState = (
  checkoutSettings: CheckoutSettingsModel,
  address?: AddressModel
): FormValues => {
  const subdivision = address?.subdivision?.includes('-') ? address?.subdivision?.split('-')[1] : address?.subdivision;
  return {
    address: {
      country: address?.country ?? DEFAULT_COUNTRY,
      city: address?.city ?? '',
      ...(subdivision ? {subdivision} : {}),
      ...(address?.postalCode ? {postal_code: address.postalCode} : {}),
      ...(address?.addressLine ? {address_line: address.addressLine} : {}),
      ...(address?.streetAddress?.name ? {street_name: address.streetAddress.name} : {}),
      ...(address?.streetAddress?.number ? {street_number: address.streetAddress.number} : {}),
      ...(checkoutSettings.addressLine2.show && address?.addressLine2 ? {address_line_2: address?.addressLine2} : {}),
    },
  };
};

export const getAddressFromAddressFormValues = (
  checkoutSettings: CheckoutSettingsModel,
  addressFormValues: FormValues
  // eslint-disable-next-line sonarjs/cognitive-complexity
): ApiAddressFragment => {
  const address = addressFormValues.address as MultilineAddressValue;
  const streetAddress =
    address.street_name || address.street_number
      ? {
          name: (address.street_name as string) || /* istanbul ignore next: test forms */ undefined,
          number: (address.street_number as string) || /* istanbul ignore next: test forms */ undefined,
        }
      : undefined;

  return {
    country: address.country as string,
    city: address.city as string,
    ...(address.subdivision
      ? {
          subdivision: `${address.country as string}-${address.subdivision}`,
        }
      : {}),
    ...(address.postal_code ? {postalCode: address.postal_code} : /* istanbul ignore next: test forms */ {}),
    ...(address.address_line ? {addressLine: address.address_line} : {}),
    ...(streetAddress ? {streetAddress} : {}),
    ...(checkoutSettings.addressLine2.show && address.address_line_2 ? {addressLine2: address.address_line_2} : {}),
  };
};

export const getCountryFromAddressFormValues = (addressFormValues: FormValues): string => {
  const address = addressFormValues.address as MultilineAddressValue;
  return address.country as string;
};
