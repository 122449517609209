import {NavigationService} from '../services/NavigationService';
import {CheckoutService} from '../services/CheckoutService';

export const isPickupFlow = ({
  navigationService,
  checkoutService,
}: {
  navigationService: NavigationService;
  checkoutService: CheckoutService;
}): boolean => {
  if (checkoutService.checkout) {
    return navigationService.isPickupFlow && Boolean(checkoutService.checkout.selectedShippingOption?.pickupInfo);
  }
  return navigationService.isPickupFlow;
};

export const isShippingFlow = ({
  navigationService,
  checkoutService,
}: {
  navigationService: NavigationService;
  checkoutService: CheckoutService;
}): boolean => {
  return !isPickupFlow({navigationService, checkoutService}) && Boolean(checkoutService.checkout?.hasShippableItems);
};
