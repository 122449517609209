import {FormValues} from '@wix/form-viewer';
import {FullNameValue} from '@wix/form-fields';
import {CheckoutSettingsModel} from '../../../../domain/models/checkoutSettings/CheckoutSettings.model';
import {ContactModel} from '../../../../domain/models/Contact.model';
import {DEFAULT_COUNTRY} from '../../constants';
import {ILocaleKeys} from '../../../../locale-keys/LocaleKeys';
import {FullAddressContactDetailsFragment} from '../../../../gql/graphql';

export const getContactFormOverrides = ({
  localeKeys,
  checkoutSettings,
  overridePhone,
}: {
  localeKeys?: ILocaleKeys;
  checkoutSettings: CheckoutSettingsModel;
  overridePhone?: boolean;
}) => {
  return {
    full_name: {
      first_name: {
        ...(localeKeys ? {label: localeKeys.checkout.address_information.first_name_label()} : {}),
      },
      last_name: {
        ...(localeKeys ? {label: localeKeys.checkout.address_information.last_name_label()} : {}),
      },
    },
    phone: {
      ...(localeKeys ? {label: localeKeys.checkout.address_information.phone_number_label()} : {}),
      hidden: !checkoutSettings.phone.show && !overridePhone,
      required: checkoutSettings.phone.mandatory || overridePhone,
    },
    company_name: {
      ...(localeKeys ? {label: localeKeys.checkout.address_information.company_label()} : {}),
      hidden: !checkoutSettings.companyName.show,
      required: checkoutSettings.companyName.mandatory,
    },
  };
};

export const getContactFormInitialState = ({
  checkoutSettings,
  contact,
  country = DEFAULT_COUNTRY,
}: {
  checkoutSettings: CheckoutSettingsModel;
  contact?: ContactModel;
  country?: string;
}): FormValues => {
  return {
    full_name: {
      first_name: contact?.firstName ?? '',
      last_name: contact?.lastName ?? '',
      country,
    },
    ...(checkoutSettings.phone.show ? {phone: contact?.phone ?? ''} : {}),
    ...(checkoutSettings.companyName.show ? {company_name: contact?.company ?? ''} : {}),
  };
};

export const getContactFormCurrentStateWithUpdatedCountry = ({
  contactFormValues,
  country,
}: {
  contactFormValues: FormValues;
  country?: string;
}): FormValues => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    ...contactFormValues,
    full_name: {
      ...fullName,
      country,
    },
  };
};

export const getContactDetailsFromContactFormValues = (
  contactFormValues: FormValues,
  checkoutSettings: CheckoutSettingsModel
): FullAddressContactDetailsFragment => {
  const fullName = contactFormValues.full_name as FullNameValue;
  return {
    firstName: fullName.first_name as string,
    lastName: fullName.last_name as string,
    ...(checkoutSettings.phone.show && contactFormValues.phone ? {phone: contactFormValues.phone as string} : {}),
    ...(checkoutSettings.companyName.show && contactFormValues.company_name
      ? {company: contactFormValues.company_name as string}
      : {}),
  };
};
