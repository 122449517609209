import {HttpError} from '@wix/http-client';
import {CheckoutErrorCode, CouponErrorCodes, ErrorType, GiftCardErrorCodes, PaymentErrorCode} from '../utils/errors';
import {ApplicationErrorFragment, ErrorDetailsFragment} from '../../gql/graphql';
import {PaymentError} from '../../types/payment.types';

export interface ErrorData {
  message: string;
  details: ErrorDetailsFragment;
}

export class CheckoutErrorModel {
  public type: string;
  public code: string;
  public data?: Record<string, any> | null;

  public static fromHttpError(httpError: HttpError): CheckoutErrorModel {
    const errorDataDetails = (httpError?.response?.data as ErrorData)?.details;
    return new CheckoutErrorModel(convertToError(errorDataDetails));
  }

  /* istanbul ignore next */
  public static fromPaymentError(paymentError: PaymentError, errorCode: string): CheckoutErrorModel {
    /* istanbul ignore next */
    return new CheckoutErrorModel({
      type: ErrorType.PAYMENT,
      code: errorCode,
      data: paymentError as Record<string, any>,
    });
  }

  constructor({code, data, type}: {code: string; data?: Record<string, any> | null; type: string}) {
    this.code = code;
    this.data = data;
    this.type = type;
  }
}

function convertToError(errorDataDetails: ErrorDetailsFragment): {
  code: string;
  data: Record<string, any> | null;
  type: string;
} {
  if (isMinimumOrderAmountError(errorDataDetails)) {
    const {
      code,
      data,
    } = // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      (errorDataDetails.applicationError!.data.orderValidationErrors as ApplicationErrorFragment[])[0];
    return {
      type: ErrorType.MINIMUM_ORDER_AMOUNT,
      code: code!,
      data: data as Record<string, any>,
    };
  }

  if (isGiftCardError(errorDataDetails)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const giftCardCalculationError = errorDataDetails?.applicationError?.data
      ?.giftCardCalculationError as ErrorDetailsFragment;
    const errorDetails = giftCardCalculationError ?? errorDataDetails;
    return getGiftCardError(errorDetails);
  }

  if (isCouponError(errorDataDetails)) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    const couponCalculationError = errorDataDetails?.applicationError?.data
      ?.couponCalculationError as ErrorDetailsFragment;
    const errorDetails = couponCalculationError ?? errorDataDetails;
    return getCouponError(errorDetails);
  }

  const code = CheckoutErrorCode[errorDataDetails?.applicationError?.code as string] ?? CheckoutErrorCode.GENERAL_ERROR;
  const data =
    (errorDataDetails?.applicationError?.data as Record<string, any>) ??
    (errorDataDetails?.validationError?.fieldViolations?.[0]?.ruleName as string) ??
    (errorDataDetails?.validationError?.fieldViolations?.[0]?.description as string);

  return {
    code,
    data,
    type: isPaymentError(code) ? ErrorType.PAYMENT : ErrorType.GENERAL,
  };
}

function isGiftCardError(errorDataDetails: ErrorDetailsFragment) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const giftCardCalculationError = errorDataDetails?.applicationError?.data
    ?.giftCardCalculationError as ErrorDetailsFragment;
  const errorDetails = giftCardCalculationError ?? errorDataDetails;
  return Boolean(
    GiftCardErrorCodes[errorDetails?.applicationError?.code as string] ??
      GiftCardErrorCodes[errorDetails?.validationError?.fieldViolations?.[0]?.description as string]
  );
}

function isMinimumOrderAmountError(errorDataDetails: ErrorDetailsFragment) {
  return Boolean(
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    (errorDataDetails?.applicationError?.data?.orderValidationErrors as ApplicationErrorFragment[])?.[0]?.code
  );
}

function isCouponError(errorDataDetails: ErrorDetailsFragment) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const couponCalculationError = errorDataDetails?.applicationError?.data
    ?.couponCalculationError as ErrorDetailsFragment;
  const errorDetails = couponCalculationError ?? errorDataDetails;
  return Boolean(
    CouponErrorCodes[errorDetails?.validationError?.fieldViolations?.[0]?.ruleName as string] ??
      CouponErrorCodes[errorDetails?.applicationError?.code as string]
  );
}

export function getGiftCardError(details: ErrorDetailsFragment) {
  const code =
    (details?.applicationError?.code as string) ??
    (details?.validationError?.fieldViolations?.[0]?.description as string);

  const data = details?.applicationError?.data as Record<string, any>;

  return {
    type: ErrorType.GIFT_CARD,
    code,
    data,
  };
}

export function getCouponError(details: ErrorDetailsFragment) {
  const code = details?.validationError?.fieldViolations?.[0]?.ruleName ?? (details?.applicationError?.code as string);
  const data = details?.validationError?.fieldViolations?.[0] as Record<string, any>;
  return {
    type: ErrorType.COUPON,
    code,
    data,
  };
}

const isPaymentError = (code: string): boolean => !!PaymentErrorCode[code];
