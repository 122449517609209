import {Payments, StoreCheckoutSettings} from '@wix/wixstores-graphql-schema-node';
import {Checkout} from '../../types/app.types';
import {CheckoutWithEnrichedItemsFragment} from '../../gql/graphql';

export interface StoreCheckoutSettingsAndPayments {
  checkoutSettings: StoreCheckoutSettings;
  payments: Payments;
}

export function isCheckoutWithEnrichedItems(checkout: Checkout): checkout is CheckoutWithEnrichedItemsFragment {
  return (checkout as CheckoutWithEnrichedItemsFragment).externalEnrichedLineItems !== undefined;
}

export function isCheckoutWithViolations(checkout: Checkout): checkout is CheckoutWithEnrichedItemsFragment {
  return (checkout as CheckoutWithEnrichedItemsFragment).violations !== undefined;
}
